import { defineComponent, computed, ref, watch, onMounted, onUnmounted, getCurrentInstance } from '@vue/composition-api';
import BackButton from '@/components/BackButton.vue';
import { dateFormat } from '@/util/dates';
import SocialShare from '@/components/SocialShare.vue';
import TrustPublication from '@/components/TrustPublication.vue';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import useStructuredData from '@/shared/composables/useStructuredData';
import { SITE_URL } from '@/App.vue';
import { useMeta } from '@/shared/composables/useMeta';
import { useGetBlogArticleQuery } from '@/generated-types/graphql.types';
import StoryblokService from '@/util/storyblok-service';
export default defineComponent({
    components: {
        TrustPublication,
        BackButton,
        SocialShare
    },
    setup(_, context) {
        const { root } = context;
        const instance = getCurrentInstance();
        const slug = computed(() => root.$route.params.slug || '');
        const article = ref(null);
        const structuredData = ref(null);
        const link = ref('');
        const publicationDate = ref('');
        const preview = root.$route.query._storyblok_version === '';
        const { onResult, refetch } = useGetBlogArticleQuery({ articleSlug: slug.value, preview });
        onResult(result => {
            if (!result?.data?.get_blog_article)
                return;
            const data = JSON.parse(result.data.get_blog_article.raw_content);
            article.value = data;
            publicationDate.value = dateFormat(data.created_at || data.first_published_at, 'DD MMM YYYY');
            link.value = `${SITE_URL}/${data.full_slug}`;
            structuredData.value = {
                '@context': 'https://schema.org',
                '@type': 'NewsArticle',
                headline: article.value?.name,
                image: [article.value?.content?.thumbnail?.filename],
                datePublished: article.value?.first_published_at,
                dateModified: article.value?.published_at,
                author: [
                    {
                        '@type': 'Person',
                        name: article.value?.content?.author
                    }
                ]
            };
            useMeta({
                title: article.value?.content?.metadata?.title || '',
                description: article.value?.content?.metadata?.description || '',
                url: link.value,
                imageUrl: article.value?.content?.thumbnail?.filename || '',
                noIndex: false
            });
            injectStructuredData(structuredData.value);
        });
        const { injectStructuredData, ejectStructuredData } = useStructuredData();
        watch(() => globalThis.$i18n.locale, () => {
            refetch();
        });
        onMounted(() => {
            refetch();
            StoryblokService.initEditor(instance);
        });
        onUnmounted(() => {
            ejectStructuredData();
        });
        return {
            article,
            link,
            publicationDate,
            useGetLocalizedPath
        };
    }
});
